

















































import { Component, Prop } from 'vue-property-decorator';
import { ViewModeType, OrgTreeType } from '@common-src/model/enum';
import { message } from 'ant-design-vue';
import OrgDepartmentService from '@common-src/service/org-department';
import UserService from '@common-src/service/user-v2';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class UserOrgBatchImportListComponent extends BaseComponent {
    @Prop()
    type: string;

    @Prop()
    importParams: any;

    typeMsg: string = '';
    fileList: any = [];

    mounted() {
        this.typeMsg = this.type === 'user' ? '成员' : '部门';
        this.fileList = [];
    }

    cancelClick() {
        this.fileList = [];
        this.$emit('cancel');
    }

    downloadTemplate() {
        this.startFullScreenLoading('正在下载...');
        if (this.type === 'user') {
            new UserService().downloadTemplate().finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            OrgDepartmentService.downloadTemplate().finally(() => {
                this.stopFullScreenLoading();
            });
        }
    }

    exportTemplate() {
        this.startFullScreenLoading('正在导出...');
        if (this.type === 'user') {
            new UserService().exportTemplate(this.importParams).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            OrgDepartmentService.exportTemplate().finally(() => {
                this.stopFullScreenLoading();
            });
        }
    }

    importTemplate() {
        if (_.isEmpty(this.fileList)) {
            message.warning('请先上传文件');
            return;
        }
        this.startFullScreenLoading('正在导入...');
        if (this.type === 'user') {
            new UserService().importTemplate(this.fileList[0]).then((res:any) => {
                if (res) {
                    message.success('导入成功');
                    this.fileList = [];
                    this.cancelClick();
                    this.$emit('ok');
                    return;
                }
                message.error('导入失败');
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        } else {
            OrgDepartmentService.importTemplate(this.fileList[0]).then((res:any) => {
                if (res) {
                    message.success('导入成功');
                    this.fileList = [];
                    this.cancelClick();
                    this.$emit('ok');
                    return;
                }
                message.error('导入失败');
            }).finally(() => {
                this.stopFullScreenLoading();
            });
        }
    }

    handleChange(e: any) {
        if (e.file.status === 'removed') {
            this.fileList = [];
        } else {
            const type = _.last(_.split(e.file.name, '.'));
            if (_.includes(['xlsx', 'xls'], type)) {
                this.fileList = [e.file];
                this.fileList[0].status = 'done';
            }
        }
    }

    beforeUpload(file:any) {
        const type = _.last(_.split(file.name, '.'));
        if (!_.includes(['xlsx', 'xls'], type)) {
            message.warning('请上传xlsx或者xls格式的文件');
            return false;
        }
        return true;
    }

    rejectUpload() {
        message.warning('请上传xlsx或者xls格式的文件');
    }
}

