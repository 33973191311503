var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-list-component table-component" }, [
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "a-layout",
          [
            _c("a-layout-content", [
              _c("div", { staticClass: "page-body-margin-left-16" }, [
                _c(
                  "div",
                  {
                    staticClass: "p-16",
                    staticStyle: { background: "#f2f2f5" },
                  },
                  [
                    _c("div", { staticClass: "m-b-16" }, [
                      _c("h3", [_vm._v("1.下载模板")]),
                    ]),
                    _c("div", { staticClass: "m-b-16" }, [
                      _vm._v(
                        "你可下载空的表格模板，录入" +
                          _vm._s(_vm.typeMsg) +
                          "信息后上传以批量新增" +
                          _vm._s(_vm.typeMsg) +
                          " "
                      ),
                      _c("a", { on: { click: _vm.downloadTemplate } }, [
                        _vm._v("点击下载"),
                      ]),
                    ]),
                    _c("div", [
                      _vm._v(
                        "或者导出包含已有" +
                          _vm._s(_vm.typeMsg) +
                          "信息的表格，修改后上传以批是更新" +
                          _vm._s(_vm.typeMsg) +
                          "设置 "
                      ),
                      _c("a", { on: { click: _vm.exportTemplate } }, [
                        _vm._v("点击导出"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "p-16 m-t-16",
                    staticStyle: { background: "#f2f2f5", height: "400px" },
                  },
                  [
                    _c("div", { staticClass: "m-b-16" }, [
                      _c("h3", [_vm._v("2. 上传完善后的模板")]),
                    ]),
                    _c("div", { staticClass: "m-b-16" }, [
                      _vm._v(
                        "更新信息的模板中，如有空的数据列，则相应字段信息会被清空，请谨慎填写"
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "a-upload-dragger",
                          {
                            attrs: {
                              "file-list": _vm.fileList,
                              name: "file",
                              accept: ".xls,.xlsx",
                              multiple: false,
                              "custom-request": function () {},
                              "before-upload": _vm.beforeUpload,
                            },
                            on: {
                              change: _vm.handleChange,
                              reject: _vm.rejectUpload,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "ant-upload-drag-icon m-t-20" },
                              [_c("a-icon", { attrs: { type: "file-excel" } })],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "ant-upload-text m-b-12" },
                              [
                                _c("a-button", { attrs: { type: "" } }, [
                                  _vm._v("选择文件"),
                                ]),
                              ],
                              1
                            ),
                            _c("p", { staticClass: "ant-upload-hint m-b-20" }, [
                              _vm._v(
                                " 下载模板并完善信息后，可直接将文件拖拽到此处进行上传。支持格式:.xls、.xlsx "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "flex m-b-20 m-t-20 fixed-bot-btn",
                        staticStyle: { "justify-content": "flex-end" },
                      },
                      [
                        _c("a-button", { on: { click: _vm.cancelClick } }, [
                          _vm._v("取消"),
                        ]),
                        _c(
                          "a-button",
                          {
                            staticClass: "m-l-20",
                            attrs: { type: "primary" },
                            on: { click: _vm.importTemplate },
                          },
                          [_vm._v("导入")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "m-b-24" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }